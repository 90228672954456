<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <CForm>
            <template slot="header"> Edit Property Type id: {{ $route.params.id }} </template>
            <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
            <CInput type="text" label="Name" placeholder="Name" v-model="name"></CInput>
            <CButton class="mr-2" color="primary" @click="update()">Save</CButton>
            <CButton color="secondary" @click="goBack">Back</CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'EditPropertyType',
  props: {
    caption: {
      type: String,
      default: 'Property Type id'
    }
  },
  data() {
    return {
      name: '',
      description: '',
      showMessage: false,
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/propertyTypeTypes'})
    },
    update() {
      let self = this
      const propertyType = { id: this.$route.params.id, name: this.name }
      this.$http
        .put('admin/property_types', propertyType)
        .then(() => {
          self.message = 'Successfully updated property type.'
          self.showAlert()
        })
        .catch(error => {
          console.error(error)
          self.$router.push({ path: '/login' })
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    let self = this
    this.$http
      .get(`admin/property_types/${this.$route.params.id}/edit`)
      .then(response => {
        self.name = response.data.property_type.name
      })
      .catch(error => {
        console.error(error)
        self.$router.push({ path: '/login' })
      })
  }
}
</script>
